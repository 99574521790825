<h1 mat-dialog-title>{{ title }}</h1>
<form [formGroup]="pluginConfForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <mat-form-field class="plugin-config-create__input">
      <mat-label> {{ 'PLUGIN.LABEL' | translate }}</mat-label>
      <mat-select formControlName="pluginId" id="pluginSelect" matNativeControl [(value)]="pluginId">
        <mat-option *ngFor="let plugin of plugins" [value]="plugin.id">
          {{ 'PLUGINS.' + plugin.name + '.NAME' | uppercase | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="selectedPlugin">
      <ng-container [ngSwitch]="selectedPlugin.name">
        <ng-container *ngSwitchCase="'pharmonweb'" formGroupName="config">
          <mat-form-field>
            <mat-label>{{ 'PHARMONWEB.CIP' | translate }}</mat-label>
            <input type="number" matInput formControlName="cip" [placeholder]="'PHARMONWEB.CIP' | translate" required
              autocomplete="off">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'PHARMONWEB.GROUP_ID' | translate }}</mat-label>
            <input matInput formControlName="groupId" [placeholder]="'PHARMONWEB.GROUP_ID' | translate" required
              autocomplete="off">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'PHARMONWEB.USER_TOKEN' | translate }}</mat-label>
            <input matInput formControlName="userToken" [placeholder]="'PHARMONWEB.USER_TOKEN' | translate" required
              autocomplete="off">
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'realEstate'" formGroupName="config">

          <!-- useLegacyAdSlide -->
          <mat-checkbox formControlName="useLegacyAdSlide">
            {{ 'REAL_ESTATE.PLUGIN_CONFIG.USE_LEGACY_AD_SLIDE' | translate }}
          </mat-checkbox>

          <!-- backgroundColor -->
          <mat-form-field>
            <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.BACKGROUND_COLOR' | translate }}</mat-label>
            <input matInput type="color" formControlName="backgroundColor">
            <mat-hint align="end">{{ 'REAL_ESTATE.PLUGIN_CONFIG.RPI3_ONLY' | translate }}</mat-hint>
          </mat-form-field>

          <!-- titleColor -->
          <mat-form-field>
            <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.TITLE_COLOR' | translate }}</mat-label>
            <input matInput type="color" formControlName="titleColor">
          </mat-form-field>

          <!-- textColor -->
          <mat-form-field>
            <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.TEXT_COLOR' | translate }}</mat-label>
            <input matInput type="color" formControlName="textColor">
            <mat-hint align="end">{{ 'REAL_ESTATE.PLUGIN_CONFIG.RPI3_ONLY' | translate }}</mat-hint>
          </mat-form-field>

          <!-- logoMediaId -->
          <div class="plugin-config-details__media-picker">
            <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.LOGO' | translate }}</mat-label>
            <div *ngIf="pluginConfForm.value.config.logoMediaId; else selectLogoButton"
              class="plugin-config-details__media-picker__media">
              <app-media-tile [mediaId]="pluginConfForm.value.config.logoMediaId"
                (click)="openMediaPicker('logoMediaId')">
              </app-media-tile>
              <button *ngIf="pluginConfForm.value.config.logoMediaId" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearMediaField('logoMediaId')">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <ng-template #selectLogoButton>
              <button mat-button type="button" (click)="openMediaPicker('logoMediaId')">
                {{ 'MEDIA.SELECT' | translate }}
              </button>
            </ng-template>
          </div>

          <!-- qrcodeMediaId -->
          <div class="plugin-config-details__media-picker">
            <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.QRCODE' | translate }}</mat-label>
            <div *ngIf="pluginConfForm.value.config.qrcodeMediaId; else selectQrcodeButton"
              class="plugin-config-details__media-picker__media">
              <app-media-tile [mediaId]="pluginConfForm.value.config.qrcodeMediaId"
                (click)="openMediaPicker('qrcodeMediaId')">
              </app-media-tile>
              <button *ngIf="pluginConfForm.value.config.qrcodeMediaId" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearMediaField('qrcodeMediaId')">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <ng-template #selectQrcodeButton>
              <button mat-button type="button" (click)="openMediaPicker('qrcodeMediaId')">
                {{ 'MEDIA.SELECT' | translate }}
              </button>
            </ng-template>
          </div>

          <!-- adMediaDuration -->
          <mat-form-field>
            <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.AD_MEDIA_DURATION' | translate }}</mat-label>
            <input type="number" matInput formControlName="adMediaDuration"
              [placeholder]="'REAL_ESTATE.PLUGIN_CONFIG.AD_MEDIA_DURATION' | translate" required autocomplete="off">
          </mat-form-field>

          <ng-container formGroupName="adSlideStyle">

            <div class="plugin-config-details__real-estate__title">
              {{ 'REAL_ESTATE.PLUGIN_CONFIG.AD_SLIDE_STYLE.TITLE' | translate }}
            </div>

            <mat-form-field>
              <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.AD_BACKGROUND_COLOR' | translate }}</mat-label>
              <input matInput type="color" formControlName="adBackgroundColor">
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.ACCENT_BACKGROUND_COLOR' | translate }}</mat-label>
              <input matInput type="color" formControlName="accentBackgroundColor">
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.CONTENT_BACKGROUND_COLOR' | translate }}</mat-label>
              <input matInput type="color" formControlName="contentBackgroundColor">
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.SUMMARY_TEXT_COLOR' | translate }}</mat-label>
              <input matInput type="color" formControlName="summaryTextColor">
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.FEATURES_TEXT_COLOR' | translate }}</mat-label>
              <input matInput type="color" formControlName="featuresTextColor">
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.CONTENT_TITLE_COLOR' | translate }}</mat-label>
              <input matInput type="color" formControlName="contentTitleColor">
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.CONTENT_TEXT_COLOR' | translate }}</mat-label>
              <input matInput type="color" formControlName="contentTextColor">
            </mat-form-field>

            <div class="plugin-config-details__real-estate__example"
              [style.background-color]="pluginConfForm.get('config.adSlideStyle.adBackgroundColor')?.value"
              [style.color]="pluginConfForm.get('config.adSlideStyle.summaryTextColor')?.value">
              {{ 'REAL_ESTATE.PLUGIN_CONFIG.SUMMARY_TEXT_EXAMPLE' | translate }}
              <div class="plugin-config-details__real-estate__example__accent"
                [style.background-color]="pluginConfForm.get('config.adSlideStyle.accentBackgroundColor')?.value">
                <div class="plugin-config-details__real-estate__example__features"
                  [style.color]="pluginConfForm.get('config.adSlideStyle.featuresTextColor')?.value">
                  {{ 'REAL_ESTATE.PLUGIN_CONFIG.FEATURES_TEXT_EXAMPLE' | translate }}
                </div>
                <div class="plugin-config-details__real-estate__example__title"
                  [style.color]="pluginConfForm.get('config.adSlideStyle.contentTitleColor')?.value">
                  {{ 'REAL_ESTATE.PLUGIN_CONFIG.CONTENT_TITLE_EXAMPLE' | translate }}
                </div>
                <div class="plugin-config-details__real-estate__example__content"
                  [style.color]="pluginConfForm.get('config.adSlideStyle.contentTextColor')?.value"
                  [style.background-color]="pluginConfForm.get('config.adSlideStyle.contentBackgroundColor')?.value">
                  {{ 'REAL_ESTATE.PLUGIN_CONFIG.CONTENT_TEXT_EXAMPLE' | translate }}
                </div>
              </div>
            </div>

            <div class="plugin-config-details__real-estate__media-style" formGroupName="mediaStyle">
              <div class="plugin-config-details__real-estate__media-style__title">
                {{ 'REAL_ESTATE.PLUGIN_CONFIG.MEDIA_STYLE.LABEL' | translate }}
              </div>
              <mat-form-field>
                <mat-label>
                  {{ 'REAL_ESTATE.PLUGIN_CONFIG.BACKGROUND_COLOR' | translate }}
                </mat-label>
                <input matInput type="color" formControlName="backgroundColor">
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.MEDIA_STYLE.OBJECT_FIT' | translate }}</mat-label>
                <mat-select matNativeControl formControlName="objectFit">
                  <mat-option value="cover">
                    {{ 'REAL_ESTATE.PLUGIN_CONFIG.MEDIA_STYLE.OBJECT_FIT_COVER' | translate }}
                  </mat-option>
                  <mat-option value="contain">
                    {{ 'REAL_ESTATE.PLUGIN_CONFIG.MEDIA_STYLE.OBJECT_FIT_CONTAIN' | translate }}
                  </mat-option>
                  <mat-option value="none">
                    {{ 'REAL_ESTATE.PLUGIN_CONFIG.MEDIA_STYLE.OBJECT_FIT_NONE' | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="plugin-config-details__real-estate__media-example">
                <div class="plugin-config-details__real-estate__media-example__media"
                  [style.background-color]="pluginConfForm.get('config.adSlideStyle.mediaStyle.backgroundColor')?.value">
                  <img [style.object-fit]="pluginConfForm.get('config.adSlideStyle.mediaStyle.objectFit')?.value"
                    src="/assets/images/plugins/real-estate.png" />
                </div>
              </div>
            </div>

            <div class="plugin-config-details__real-estate__text-style" formGroupName="favoriteStyle">
              <div class="plugin-config-details__real-estate__text-style__title">
                {{ 'REAL_ESTATE.PLUGIN_CONFIG.FAVORITE_STYLE.LABEL' | translate }}
              </div>
              <mat-form-field>
                <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.BACKGROUND_COLOR' | translate }}</mat-label>
                <input matInput type="color" formControlName="backgroundColor">
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.TEXT_COLOR' | translate }}</mat-label>
                <input matInput type="color" formControlName="textColor">
              </mat-form-field>
              <div class="plugin-config-details__real-estate__example"
                [style.color]="pluginConfForm.get('config.adSlideStyle.favoriteStyle.textColor')?.value"
                [style.background-color]="pluginConfForm.get('config.adSlideStyle.favoriteStyle.backgroundColor')?.value">
                {{ 'REAL_ESTATE.PLUGIN_CONFIG.FAVORITE_STYLE.EXAMPLE' | translate }}
              </div>
            </div>

            <div class="plugin-config-details__real-estate__text-style" formGroupName="exclusivityStyle">
              <div class="plugin-config-details__real-estate__text-style__title">
                {{ 'REAL_ESTATE.PLUGIN_CONFIG.EXCLUSIVITY_STYLE.LABEL' | translate }}
              </div>
              <mat-form-field>
                <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.BACKGROUND_COLOR' | translate }}</mat-label>
                <input matInput type="color" formControlName="backgroundColor">
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.TEXT_COLOR' | translate }}</mat-label>
                <input matInput type="color" formControlName="textColor">
              </mat-form-field>
              <div class="plugin-config-details__real-estate__example"
                [style.color]="pluginConfForm.get('config.adSlideStyle.exclusivityStyle.textColor')?.value"
                [style.background-color]="pluginConfForm.get('config.adSlideStyle.exclusivityStyle.backgroundColor')?.value">
                {{ 'REAL_ESTATE.PLUGIN_CONFIG.EXCLUSIVITY_STYLE.EXAMPLE' | translate }}
              </div>
            </div>

            <div class="plugin-config-details__real-estate__text-style" formGroupName="adTypeStyle">
              <div class="plugin-config-details__real-estate__text-style__title">
                {{ 'REAL_ESTATE.PLUGIN_CONFIG.AD_TYPE_STYLE.LABEL' | translate }}
              </div>
              <mat-form-field>
                <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.BACKGROUND_COLOR' | translate }}</mat-label>
                <input matInput type="color" formControlName="backgroundColor">
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'REAL_ESTATE.PLUGIN_CONFIG.TEXT_COLOR' | translate }}</mat-label>
                <input matInput type="color" formControlName="textColor">
              </mat-form-field>
              <div class="plugin-config-details__real-estate__example"
                [style.color]="pluginConfForm.get('config.adSlideStyle.adTypeStyle.textColor')?.value"
                [style.background-color]="pluginConfForm.get('config.adSlideStyle.adTypeStyle.backgroundColor')?.value">
                {{ 'REAL_ESTATE.PLUGIN_CONFIG.AD_TYPE_STYLE.EXAMPLE' | translate }}
              </div>
            </div>

            <button mat-button type="button" (click)="resetAdSlideStyle()">
              {{ 'REAL_ESTATE.PLUGIN_CONFIG.AD_SLIDE_STYLE.RESET' | translate }}
            </button>
          </ng-container>

        </ng-container>
        <ng-container *ngSwitchCase="'automobile'">

          <!-- logo -->
          <div class="plugin-config-details__auto-container" formGroupName="config">
            <mat-slide-toggle class="plugin-config-details__auto-container__show-logo" formControlName="showLogo">
              {{ 'AUTOMOBILE.PLUGIN_CONFIG.DISPLAY_LOGO' | translate }}
            </mat-slide-toggle>

            <!-- customized duration -->
            <mat-form-field>
              <mat-label>
                {{ 'AUTOMOBILE.PLUGIN_CONFIG.DURATION.LABEL' | translate }}
              </mat-label>
              <input matInput type="number" min="3" formControlName="defaultDuration"
                     [placeholder]="'AUTOMOBILE.PLUGIN_CONFIG.DURATION.PLACEHOLDER' | translate">
            </mat-form-field>
          </div>

        </ng-container>
        <ng-container *ngSwitchCase="'opinionSystem'">
          <mat-form-field formGroupName="config">
            <mat-label>{{ 'OPINION_SYSTEM.PLUGIN_CONFIG.API_KEY' | translate }}</mat-label>
            <input type="string" matInput formControlName="apiKey" [placeholder]="'OPINION_SYSTEM.PLUGIN_CONFIG.API_KEY' | translate" required
              autocomplete="off">
          </mat-form-field>
          <div class="plugin-config-details__media-picker" formGroupName="config">
            <mat-label>{{ 'OPINION_SYSTEM.PLUGIN_CONFIG.LOGO' | translate }}</mat-label>
            <div *ngIf="pluginConfForm.value.config.logoMediaId; else selectLogoButton"
              class="plugin-config-details__media-picker__media">
              <app-media-tile [mediaId]="pluginConfForm.value.config.logoMediaId"
                (click)="openMediaPicker('logoMediaId')">
              </app-media-tile>
              <button *ngIf="pluginConfForm.value.config.logoMediaId" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearMediaField('logoMediaId')">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <ng-template #selectLogoButton>
              <button mat-button type="button" (click)="openMediaPicker('logoMediaId')">
                {{ 'MEDIA.SELECT' | translate }}
              </button>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate }}</button>
    <button mat-raised-button color="accent" [disabled]="!pluginConfForm.valid" type="submit">
      <mat-icon>done</mat-icon>
      {{ 'VALIDATE' | translate }}
    </button>
  </mat-dialog-actions>
</form>
