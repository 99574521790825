import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AppPlugin, AppPluginConfig, PluginConfigService, PluginService } from "hcl-lib";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MediaType } from "../../../interfaces/media";
import { MediaPickerDialogComponent } from "../../../Layout/Components/widget/media-picker-dialog/media-picker-dialog.component";
import { NgFor, NgIf, NgSwitch, NgSwitchCase, UpperCasePipe } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MediaTileComponent } from "../../../Layout/Components/widget/media-tile/media-tile.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

export interface PluginConfigDetails {
  create: boolean;
  config: AppPluginConfig;
}

@Component({
    selector: 'app-plugin-config-details-dialog',
    templateUrl: './plugin-config-details-dialog.component.html',
    styleUrls: ['./plugin-config-details-dialog.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, NgSwitch, NgSwitchCase, UpperCasePipe, ReactiveFormsModule, MatFormFieldModule, MatCheckboxModule, MatSelectModule, MatInputModule, MatSlideToggleModule, MatDialogModule, MatButtonModule, MatIconModule, TranslateModule, MediaTileComponent]
})
export class PluginConfigDetailsDialogComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  title!: string
  pluginConfForm!: UntypedFormGroup
  plugins: AppPlugin[] = []
  pluginId?: string
  selectedPlugin?: AppPlugin | null = null
  create!: boolean

  constructor(
    public dialogRef: MatDialogRef<PluginConfigDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PluginConfigDetails,
    private matDialog: MatDialog,
    private pluginService: PluginService,
    private pluginConfigService: PluginConfigService,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.create = this.data.create
    if (this.create) {
      this.title = this.translateService.instant('PLUGIN.CONFIG.ADD_TITLE')
    } else {
      this.title = this.translateService.instant('PLUGIN.CONFIG.UPDATE_TITLE')
    }
    const pluginId = this.create ? null : this.data.config.pluginId
    this.pluginConfForm = this.formBuilder.group({
      pluginId: [{ value: pluginId, disabled: !this.create }],
      config: this.formBuilder.group({})
    })
    this.retrievePlugins()
    this.subscriptions.add(
      this.pluginConfForm.get("pluginId")?.valueChanges.subscribe(pluginId => this.selectPlugin(pluginId))
    )
  }

  retrievePlugins(): void {
    this.subscriptions.add(
      this.pluginService.getPlugins().subscribe((plugins) => {
        this.plugins = plugins
        this.pluginId = this.data.config.pluginId
        this.pluginConfForm.patchValue({ pluginId: this.pluginId })
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  onSubmit() {
    let pluginConfig: AppPluginConfig = {
      pluginId: this.pluginConfForm.get("pluginId")?.value,
      config: this.pluginConfForm.get("config")?.value
    }
    if (!this.create) {
      pluginConfig.id = this.data.config.id
    }
    const config$ = this.create ? this.pluginConfigService.createPluginConfig(pluginConfig)
      : this.pluginConfigService.updatePluginConfig(pluginConfig)
    this.subscriptions.add(
      config$.subscribe(_ => {
        this.dialogRef.close()
      })
    )
  }

  selectPlugin(pluginId: string) {
    const plugin = this.plugins.find(plugin => plugin.id == pluginId);
    this.pluginConfForm.removeControl("config")
    let configForm = this.formBuilder.group({});
    if (plugin) {
      switch (plugin.name) {
        case "pharmonweb":
          configForm = this.formBuilder.group({
            cip: [this.data.config.config.cip, Validators.compose(
              [Validators.required, Validators.min(1000000), Validators.max(9999999999999)])],
            groupId: [this.data.config.config.groupId, [Validators.required]],
            userToken: [this.data.config.config.userToken, [Validators.required]]
          })
          break
        case "realEstate":
          configForm = this.formBuilder.group({
            backgroundColor: ["#ffffff"],
            titleColor: ["#000000"],
            textColor: ["#000000"],
            logoMediaId: [null],
            qrcodeMediaId: [null],
            adMediaDuration: [5000, Validators.compose([Validators.min(1000), Validators.max(60000)])],
            useLegacyAdSlide: [false],
            adSlideStyle: this.getAdSlideStyleForm()
          })
          break
        case "automobile":
          configForm = this.formBuilder.group({
            showLogo: [true],
            defaultDuration: [10, Validators.compose([Validators.min(3), Validators.max(100)])]
          })
          break
          case "opinionSystem":
            configForm = this.formBuilder.group({
              logoMediaId: [null],
              apiKey: [this.data.config.config.apiKey, [Validators.required]]

            })
            break
      }
    }
    configForm.patchValue(this.data.config.config)
    this.pluginConfForm.addControl("config", configForm)
    this.selectedPlugin = plugin
    this.cdRef.detectChanges()
  }

  openMediaPicker(controlName: string): void {
    const config = new MatDialogConfig()
    config.data = {
      mediaTypes: [MediaType.Image],
      publicMediaTypes: [MediaType.Image],
      actionLabel: this.translateService.instant("MEDIA_PICKER_DIALOG.LABEL.SELECT")
    }
    const mediaPickerDialogRef = this.matDialog.open(MediaPickerDialogComponent, config)
    this.subscriptions.add(
      mediaPickerDialogRef.afterClosed().subscribe(selectedMedia => {
        if (selectedMedia) {
          this.pluginConfForm.get('config')?.patchValue({
            [controlName]: selectedMedia.id
          })
        }
      })
    )
  }

  clearMediaField(controlName: string): void {
    this.pluginConfForm.get('config')?.patchValue({
      [controlName]: null
    })
  }

  getAdSlideStyleForm(): UntypedFormGroup {
    return this.formBuilder.group({
      adBackgroundColor: ["#e0e0e0"],
      accentBackgroundColor: ["#efefef"],
      contentBackgroundColor: ["#ffffff"],
      summaryTextColor: ["#000000"],
      featuresTextColor: ["#000000"],
      contentTitleColor: ["#000000"],
      contentTextColor: ["#000000"],
      mediaStyle: this.formBuilder.group({
        backgroundColor: ["#e0e0e0"],
        objectFit: ["cover"]
      }),
      favoriteStyle: this.formBuilder.group({
        backgroundColor: ["#ff5656"],
        textColor: ["#ffffff"]
      }),
      exclusivityStyle: this.formBuilder.group({
        backgroundColor: ["#c12e2e"],
        textColor: ["#ffffff"]
      }),
      adTypeStyle: this.formBuilder.group({
        backgroundColor: ["#e6e6e6"],
        textColor: ["#000000"]
      })
    })
  }

  resetAdSlideStyle(): void {
    let configForm = this.pluginConfForm.get('config') as UntypedFormGroup
    configForm.removeControl('adSlideStyle')
    configForm.addControl('adSlideStyle', this.getAdSlideStyleForm())
  }
}
